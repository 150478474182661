:root {
  --blue: #006FA7;
  --white: #ffffff;
  --lightgray: #999999;
  --darkgray: #6c757d;
  --border: #dee2e6; 
  --lightblue: #e7f4ff;
}

.bg-dark {
  background-color: #1B1212 !important;
}

.bg-lightblue {
  background-color: var(--lightblue);
}

.table_border .ReactTable .rt-thead.-headerGroups, .table_border .ReactTable .rt-thead .rt-th {
  border-bottom: 0 !important;
  color: #1B1212;
  text-align: left;
}

.table_border .ReactTable .rt-thead .rt-th, .ReactTable .rt-thead .rt-td {
  font-weight: 500;
}

.table_border .ReactTable .rt-thead {
  border-bottom: 3px solid #e9ecef;
  overflow: hidden !important;

}

.table_border .ReactTable .rt-tbody{
  overflow: initial;
  color: #999999;
}

/* .table_border ::-webkit-scrollbar {
  width: 1px !important;
  height: 13px;
 }  */

.tbl_round .table_border .ReactTable {
  border-radius: '0.3rem' 
}

.selectable tr {
  border-bottom : 1px solid var(--border);
  text-align: center;
}

.selectable .nav-link {
 color: #1B1212;
 font-size: 12px;
 width: max-content;
}

.selectable .nav-link.active {
  color: #1B1212;
  font-size: 11px;
  font-weight: bold;
 }

.progressbar_color .progress-bar  {
  background-image: linear-gradient(120deg, #9FE6A4 0%, #4ED16B 32%, #2858DE 103%);
}

.page_header .app-page-title {
  padding: 10px 30px;
}

.page_header .app-page-title .page-title-icon {
  width: 50px !important;
  height: 50px !important;
  padding: 0.4533rem;
}

.menu-header-title {
  font-weight: 300;
  font-size: 1.05rem;
  margin: 0 0 0 0.7rem;
}

.align_tab .nav-link {
  display: block;
}

.align_tab .nav-pills .nav-link {
  border-radius: 0px;
  color: black;
  font-size: 1rem;
  margin: auto;
}

.align_tab .nav-pills .nav-link.active, .style_tab .nav-pills .nav-link.active{
  background: var(--blue) !important;
  color: white;
}

.align_tab .nav-justified .nav-link {
  color: #1B1212;
  background-color: #E8E6E5;
}

.align_tab .nav-justified .nav-link.active {
  background-color: white;
  color: var(--blue);
}

.align_tab ul {
  padding: 0;
  display:table;
  width:100%;
  box-sizing:border-box;
  -moz-box-sizing:border-box;
  -webkit-box-sizing:border-box;
}
.align_tab li {
  display:table-cell;
}

.style_tab .nav-pills .nav-link {
  color: var(--blue);
  font-size: 1rem;
  margin: auto;
}

.tabs-lg-alternate > .nav .nav-link.active {
  color: #666;
}

.tabs-lg-alternate > .nav .nav-link {
  text-transform: none;
  width: 100%;
  display: block;
  color: #495057;
  font-size: 1rem;
}

.tabs-lg-alternate > .nav .nav-link.active {
  color: var(--blue);
}

.tabs-lg-alternate.card-header > .nav .nav-link {
  padding: 0;
  border-right: #e9ecef solid 1px;
  background: #f8f9fa;
}

.card_radius .card {
  border-radius: 15px;
  box-shadow: none;
}

.card_radius .card > .dropdown-menu-header .dropdown-menu-header-inner, .card_radius .dropdown-menu-header .menu-header-image {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.card_radius .ReactTable {
  border-radius: 0 0 15px 15px;
}

.tab_blue .rc-tabs-top .rc-tabs-ink-bar {
  height: 35px;
  bottom: 0;
  left: 0;
  background: var(--blue);
}

.tab_blue .rc-tabs-tab-active, .rc-tabs-tab-active:hover {
  color: #fff !important;
  box-shadow: none !important;
}

.tab_blue .rc-tabs-ink-bar {
  box-shadow: none;
}

.tab_blue .rc-tabs-tab {
  color: var(--blue);
}

.tab_blue .rc-tabs-top .rc-tabs-nav-wrap {
  padding: 0.7rem 15px;
}

.tab_center .rc-tabs-top .rc-tabs-nav-wrap {
  text-align: center;
  display: flex;
}

.tab_blue .rc-tabs-nav {
  float: none;
}

.audio_without_timeline audio::-webkit-media-controls-timeline,
video::-webkit-media-controls-timeline {
  display: none;
}

.multi_step .forms-wizard li.form-wizard-step-doing::before,
.multi_step .forms-wizard li.form-wizard-step-doing em {
    background-color: #0069AB;
}

.custom_input_group {
  position: relative;
  border-radius: 2px;
}
.custom_input_group input {
  border: none;
  width: 100%;
  padding-right: 123px;

}
.custom_input_group .input-group-append {
  position: absolute;
  right: 4px;
  top: 4px;
  bottom: 4px;z-index:9;
  margin-left: 1px;
}
.custom_input_group .rbt-token {
  background-color: var(--blue);
  color: white;
}

.custom_input_group .rbt-token .rbt-token-remove-button {
  color: white;
}

.custom_input_group .rbt-input-multi .rbt-input-wrapper {
  margin-top: 0;
}

.rbt-input-multi.form-control {
  height: auto;
}

.light_typeahead .rbt-token {
  background-color: #e7f4ff;
  border: 1px solid #007bff !important;
  color: #1B1212;
}

.light_typeahead .rbt-menu.dropdown-menu.show {
  animation: none;
}
.horizontal-scrollable > .row {
  overflow-x: auto;
  white-space: nowrap;
}

.horizontal-scrollable > .row > .col-3 {
  display: inline-block;
  float: none;
}

.col .nav-link .active {
  background-color: var(--blue);
}

.data_table ::-webkit-scrollbar {
  /* width: 10px; */
}

.text_editor .cke_chrome{
  border-radius: 10px;
  border: 1px solid #d1d1d1;
}

.text_editor .cke_top{
  border-radius: 5px 5px 0px 0px;
  border: 1px solid #d1d1d1
}

.text_editor .cke_bottom{
  border-radius: 0px 0px 10px 10px;
  border: 1px solid #d1d1d1
}
.modal_form .modal-dialog {
  box-shadow: 'none' !important;
}

.tab_blue .RRT__inkbar {
  background-color: var(--blue) !important;
  background-image: none !important;
  
}

.tab_blue .rc-tabs-tab-prev-icon, .rc-tabs-tab-next-icon {
  vertical-align: text-top;
}

.tab_blue .body-tabs.body-tabs-layout.body-tabs-big .RRT__tab.RRT__tab--selected:hover, 
.tab_blue .body-tabs.body-tabs-layout.body-tabs-big .RRT__tab.RRT__tab--selected {
  color: #fff;
  background: var(--blue);
}

.tab_blue .body-tabs > .RRT__tab {
  font-weight:  500;
}

.tab_blue .body-tabs.body-tabs-layout.body-tabs-big .RRT__tab {
  display: inline-block !important;
  padding: 0.5rem 1.5rem !important;
  border-radius: 2rem;
  color: var(--blue);
    font-weight: normal;
    line-height: 40px;
    height: 60px;

}

.tab_blue .body-tabs.body-tabs-layout.body-tabs-big {
  background: #fff;
  border: 1px solid #80808026;
  border-radius: 2.8rem;
}

/* .body-tabs.body-tabs-layout.body-tabs-big .RRT__tab {
  margin: 0;
  font-size: 0.9rem;
  font-weight: 600;
  padding: 0;
  height: 50px;
  flex: 1 1;
  text-align: center;
  position: relative;
  color: #666;
  letter-spacing: 0.5px;
  align-items: center;
  line-height: 30px;
} */
.tab_blue .body-tabs.body-tabs-layout.body-tabs-big .RRT__showmore 
.RRT__showmore-list .RRT__tab.RRT__tab--selected {
  border-radius: 0;
  background-color: #ccc;
}

.custom_slider .slick-dots li button {
  background: #ccc;
}

.custom_drop .dropdown-menu::before, .custom_drop .dropdown-menu::after {
  right: 5px !important;
  left: auto !important;
}

.custom_drop .small_typeahead .dropdown-menu {
  height: 220px !important;
}

.participant_card .widget-content .widget-content-left .widget-heading, .participant_card .widget-content .widget-content-left .widget-subheading  {
  opacity: 10;
  color: var(--white)
}

.participant_btn .btn-secondary, .participant_btn .btn-secondary:hover, .participant_btn .btn-secondary:active, .participant_btn .btn-secondary:focus {
  background-color: transparent !important;
  border-color: transparent !important;
}

.admin_header .page_header .app-page-title .page-title-icon {
  padding: 0.7rem !important;
}

.activity_header .page_header .app-page-title .page-title-icon {
  padding: 0.6rem !important;
}

.excerpt_header .page_header .app-page-title .page-title-icon {
  padding: 1rem !important;
}

.project_header .page_header .app-page-title .page-title-icon {
  padding: 0.8rem !important;
}

.create_header .page_header .app-page-title .page-title-icon {
  padding: 0.3rem !important;
}

/* .options .input-group {
  border-radius: 5px;
}

.options .input-group:focus-within {
  border-color: 1px solid #bcbff0;
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
  color: #495057;
  background-color: #fff;
  outline: 0;

}

.options .form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: none;
  outline: 0;
  box-shadow: none;
} */

.input-group-feedback .valid-feedback {
  display: block;
  color: var(--blue);
}

.custom_drop .btn-secondary.dropdown-toggle, .custom_drop .btn-secondary.dropdown-toggle:focus {
  color: #fff;
  background-color: var(--blue);
  border-color: var(--blue);
}

.report_tbl ._survey-steps_ .table-responsive {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.journal_activity .rbc-event {
  background-color: var(--blue);
}

.member_card .dropdown-menu-header .dropdown-menu-header-inner {
  margin: 0;
}
.member_card .grid-menu [class*="col-"]:nth-child(1n) {
  border-right-width: 0;
}
.chat-footer .app-footer {
  left: auto !important;
}
.chat-footer .app-footer .app-footer__inner {
  margin-left: 0 !important;
}
.admin-chat .chat-box-wrapper .chat-box {
  border-radius: 5px !important;
  border-top-left-radius: 5px !important;
}
.admin-chat .avatar-icon {
  border: 2px solid var(--border)
}
.admin-chat .dropdown-item.active, .dropdown-item:active {
  color: #fff;
  background-color: var(--blue);
}
.badge_success .rbt-token {
  background-color: #ebfff9 !important;
  color: #42ba96 !important; 
  border: 1px solid #42ba96 !important;
} 
.badge_danger .rbt-token {
  background-color: #fde6e6  !important;
  color: #ff3d3d !important; 
  border: 1px solid #ff3d3d !important;
} 
.badge_primary .rbt-token {
  background-color: rgb(232, 240, 254) !important;
  color: #006FA7 !important; 
  border: 1px solid #006FA7 !important;
} 

.custom_drop .dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: var(--blue);
}

.Toastify__toast--info {
  background: var(--white);
  color: var(--darkgray);
}

.Toastify__progress-bar {
  background-color: var(--lightgray);
}

.infinite-scroll-component {
  overflow-x: hidden !important ;
}

.hide-file-text input[type='file'] {
  color: transparent !important;
}

.chat-layout .chat-box-wrapper {
  padding: 0.2rem;
}

.scrollbar ::-webkit-scrollbar {
  width: 10px !important;
}

@media (max-width: 991.98px) {
  .page_header .app-page-title .page-title-heading, .app-page-title .page-title-wrapper {
    margin: 0; 
    display: flex;
  }
  .page_header .app-page-title .page-title-icon, .ui-theme-settings {
    display: flex !important;
  }
  .app-page-title .page-title-actions {
    margin: 0 3px 0 auto;
  }
  .custom_drop .popover, .custom_drop .dropdown-menu {
    position: absolute !important;
    -webkit-transform: none !important;
    transform: none !important;
    min-width: 15rem !important;
  }
  .tab_blue .RRT__showmore-list--opened {
    display: grid;
  }
  .tab_blue .body-tabs.body-tabs-layout.body-tabs-big {
    display: flex !important;
  }
    .table_border .ReactTable .-pagination .-center {
    display: block;
  }
  .tab_blue .body-tabs.body-tabs-layout.body-tabs-big .RRT__showmore {
    z-index: 5;
  }
}


@media (max-width: 768px) {
  .multi_step .forms-wizard {
    /* width: auto !important; */
  }
  .multi_step .forms-wizard li em {
    margin-top: 1.3rem !important;
  }
  .multi_step .forms-wizard li {
    vertical-align: text-top;
  }
}

/* @media (max-width: 400px) {
  .tab_blue .body-tabs.body-tabs-layout.body-tabs-big {
    display: block !important;
  }
} */

