




.profile_uploader {
    width: 150px;
    height: 150px;
    border-radius: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    box-shadow: 0 0 11px 1px #adadad;
    border: 1px solid #ccc;
}

.profile_upload_setting_icon {
    background-image: url(../images/setting_icon.png);
    background-size: 26px;
    background-repeat: no-repeat;
    background-position: center;
    display: block;
    background-color: #fff;
    border-radius: 100%;
    float: right;
    margin-top: 11px;
    margin-right: 2px;
    padding: 16px;
    cursor: pointer;
}

.profile_upload_setting_icon:hover {
    box-shadow: 0 0 11px 1px #ccc;
    opacity: 0.9;
}


.card_border_header {
    border-bottom: 2px solid #ddd;
    padding: 0.5rem 1rem;
    color: #999;
    font-size: 0.95rem;
    font-weight: 500;
}

.card_border_body {
    padding: 1.5rem 0.8rem;
}

.point_icon {
    background-image: url(../images/points_icon.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    display: inline-block;
}

.apparel_icon {
    background-image: url(../images/apparel_icon.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    display: inline-block;
}

.foodBev_icon {
    background-image: url(../images/foodBev_icon.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    display: inline-block;
}

.it_icon {
    background-image: url(../images/it_icon.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    display: inline-block;
}

.fmcg_icon {
    background-image: url(../images/FMCG.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    display: inline-block;
}

.btn_light_border {
    border: 1px solid #f2f1f1;
    background-color: #fff;
    padding: 0.2rem 1.4rem;
    border-radius: 8px;
    box-shadow: 0 0 4px 2px #e1e1e1;
    color: #666;
    font-weight: 500;
    letter-spacing: 0.2px;
}

.btn_light_border:hover {
    cursor: pointer;
    opacity: 0.7;
}

.refer_link input {
    color: #a0afc7;
    font-size: 0.85rem;
    border: 2px solid #eaebeb;
}

.copy_txt_icon {
    background-image: url(../images/copy_txt_icon.png);
    background-size: 20px;
    background-repeat: no-repeat;
    background-position: center;
    display: inline-block;
    background-color: #3b4fc1;
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
    padding: 1rem;
}

.refer_link .copy_txt_icon:hover {
    opacity: 0.8;
    cursor: pointer;
}

.circle_number_bullet {
    display: inline-block;
    color: #666;
    font-size: 0.9rem;
    font-weight: 600;
    border: 2px solid #666;
    border-radius: 100%;
    width: 25px;
    text-align: center;
}

.arrow_right_icon {
    background-image: url(../images/arrow_right.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    display: inline-block;
    width: 15px;
    height: 15px;
}

.sidebar_logo {
    background-image: url(../images/logo.png);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 200px;
    display: block !important;
    margin: 0 auto;
    padding: 1rem;
}

.logo_container {
    display: flex;
    height: 66px;
    padding: 0.2rem 1.5rem !important;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 11;
    margin-top: 1.2rem;
}

.sidebar_menu_icon {
    background-size: 25px;
    background-repeat: no-repeat;
    background-position: center;
    display: inline-block;
}

 .dashboard_ico {
    background-image: url(../images/dashboard_icon.png);
}
/*
.profile_icon {
    background-image: url(../images/profile_icon.png);
}





.research_histroy_icon {
    background-image: url(../images/research_history_icon.png);
} */

.referral_icon {
    background-image: url(../images/referral_icon.png);
}

.forum_icon {
    background-image: url(../images/forum_icon.png);
}

.group_form_icons {
    width: 24px;
    height: 24px;
    background-size: 20px;
    background-repeat: no-repeat;
    background-position: center;
    display: inline-block;
}

.calender_icon {
    background-image: url(../images/calender_icon.png);
}

.group_user_icon {
    background-image: url(../images/group_user_ico.png);
}

.group_reply_icon {
    background-image: url(../images/group_reply_ico.png);
}

.upload_media_icon {
    background-image: url(../images/media_upload_ico.png);
    width: 35px;
    height: 35px;
    background-size: 30px;
    background-repeat: no-repeat;
    background-position: center;
    display: inline-block;
    cursor: pointer;
    opacity: 0.7;
}

.upload_media_icon:hover {
    opacity: 1;
}

.upload_video_icon {
    background-image: url(../images/video_upload_icon.png);
    width: 35px;
    height: 35px;
    background-size: 30px;
    background-repeat: no-repeat;
    background-position: center;
    display: inline-block;
    cursor: pointer;
    opacity: 0.7;
}

.upload_video_icon:hover {
    opacity: 1;
}

.upload_file_icon {
    background-image: url(../images/attachement_icon.png);
    width: 35px;
    height: 35px;
    background-size: 30px;
    background-repeat: no-repeat;
    background-position: center;
    display: inline-block;
    cursor: pointer;
    opacity: 0.7;
}

.upload_file_icon:hover {
    opacity: 1;
}

.reply_comment_textbox {
    font-size: 0.95rem;
    color: #444;
}

.sent_gradient_btn {
    background-image: linear-gradient(120deg, #9fe6a4 0%, #4ED16B 32%, #2858de 103%);
    border-radius: 8px;
    border: none;
}

.sent_icon {
    background-image: url(../images/sent_ico.png);
    background-size: 35px;
    background-repeat: no-repeat;
    background-position: center;
    display: inline-block;
    padding: 1.3rem;
    vertical-align: middle;
}

.sent_gradient_btn:hover {
    opacity: 0.8;
}

.comment_icon {
    background-image: url(../images/comment_icon.png);
}

.arrow_left_icon {
    background-image: url(../images/arrow_left.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    display: inline-block;
}

.icon_placer {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    display: inline-block;
}

.invitation_icon {
    background-image: url(../images/send_invitation.png);
}

.registration_icon {
    background-image: url(../images/registeration.png);
}

.reward_icon {
    background-image: url(../images/get_rewards.png);
}

.btn_outline_blue {
    border: 2px solid #0471a8;
    color: #0471a8;
    border-radius: 10px;
    padding: 0.5rem 0.8rem;
    font-size: 1.05rem;
    font-weight: 600;
    letter-spacing: 0.5px;
}

.btn_outline_blue:hover {
    color: #fff;
    background-color: #0471a8;
}

.profile_uploader {
    width: 150px;
    height: 150px;
    border-radius: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    box-shadow: 0 0 11px 1px #adadad;
    border: 1px solid #ccc;
}

.profile_upload_setting_icon {
    background-image: url(../images/setting_icon.png);
    background-size: 26px;
    background-repeat: no-repeat;
    background-position: center;
    display: block;
    background-color: #fff;
    border-radius: 100%;
    float: right;
    margin-top: 11px;
    margin-right: 2px;
    padding: 16px;
    cursor: pointer;
}

.profile_upload_setting_icon:hover {
    box-shadow: 0 0 11px 1px #ccc;
    opacity: 0.9;
}

.card_border {
    border: 2px solid #ddd;
    border-radius: 10px;
    
}

.card_border_header {
    border-bottom: 2px solid #ddd;
    padding: 0.5rem 1rem;
    color: #999;
    font-size: 0.95rem;
    font-weight: 500;
}

.card_border_body {
    padding: 1.5rem 0.8rem;
}



.point_icon {
    background-image: url(../images/points_icon.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    display: inline-block;
}

.apparel_icon {
    background-image: url(../images/apparel_icon.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    display: inline-block;
}

.foodBev_icon {
    background-image: url(../images/foodBev_icon.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    display: inline-block;
}

.it_icon {
    background-image: url(../images/it_icon.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    display: inline-block;
}

.fmcg_icon {
    background-image: url(../images/FMCG.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    display: inline-block;
}

.btn_light_border {
    border: 1px solid #f2f1f1;
    background-color: #fff;
    padding: 0.2rem 1.4rem;
    border-radius: 8px;
    box-shadow: 0 0 4px 2px #e1e1e1;
    color: #666;
    font-weight: 500;
    letter-spacing: 0.2px;
}

.btn_light_border:hover {
    cursor: pointer;
    opacity: 0.7;
}

.refer_link input {
    color: #a0afc7;
    font-size: 0.85rem;
    border: 2px solid #eaebeb;
}

.copy_txt_icon {
    background-image: url(../images/copy_txt_icon.png);
    background-size: 20px;
    background-repeat: no-repeat;
    background-position: center;
    display: inline-block;
    background-color: #3b4fc1;
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
    padding: 1rem;
}

.refer_link .copy_txt_icon:hover {
    opacity: 0.8;
    cursor: pointer;
}

.circle_number_bullet {
    display: inline-block;
    color: #666;
    font-size: 0.9rem;
    font-weight: 600;
    border: 2px solid #666;
    border-radius: 100%;
    width: 25px;
    text-align: center;
}

.arrow_right_icon {
    background-image: url(../images/arrow_right.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    display: inline-block;
    width: 15px;
    height: 15px;
}

.sidebar_logo {
    background-image: url(../images/logo.png);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 200px;
    display: block !important;
    margin: 0 auto;
    padding: 1rem;
}

.logo_container {
    display: flex;
    height: 66px;
    padding: 0.2rem 1.5rem !important;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 11;
    margin-top: 1.2rem;
}

.sidebar_menu_icon {
    background-size: 25px;
    background-repeat: no-repeat;
    background-position: center;
    display: inline-block;
}

.sidebar_menu_icon_new {
    background-size: 25px;
    background-repeat: no-repeat;
    background-position: center;
    display: inline-block;
    padding-top: 200px;
}
.sidebar_menu_icon_new_last {
    background-size: 25px;
    background-repeat: no-repeat;
    background-position: center;
    display: inline-block;
    padding-top: 135px;
}

/* 
.dashboard_ico {
    background-image: url(../images/dashboard_icon.png);
}

.profile_icon {
    background-image: url(../images/profile_icon.png);
}

.referral_icon {
    background-image: url(../images/referral_icon.png);
}

.research_histroy_icon {
    background-image: url(../images/research_history_icon.png);
} */

.group_form_icons {
    width: 24px;
    height: 24px;
    background-size: 20px;
    background-repeat: no-repeat;
    background-position: center;
    display: inline-block;
}

.calender_icon {
    background-image: url(../images/calender_icon.png);
}

.group_user_icon {
    background-image: url(../images/group_user_ico.png);
}

.group_reply_icon {
    background-image: url(../images/group_reply_ico.png);
}

.upload_media_icon {
    background-image: url(../images/media_upload_ico.png);
    width: 35px;
    height: 35px;
    background-size: 30px;
    background-repeat: no-repeat;
    background-position: center;
    display: inline-block;
    cursor: pointer;
    opacity: 0.7;
}

.upload_media_icon:hover {
    opacity: 1;
}

.reply_comment_textbox {
    font-size: 0.95rem;
    color: #444;
}

.sent_gradient_btn {
    background-image: linear-gradient(120deg, #9fe6a4 0%, #4ED16B 32%, #2858de 103%);
    border-radius: 8px;
    border: none;
}

.sent_icon {
    background-image: url(../images/sent_ico.png);
    background-size: 35px;
    background-repeat: no-repeat;
    background-position: center;
    display: inline-block;
    padding: 1.3rem;
    vertical-align: middle;
}

.sent_gradient_btn:hover {
    opacity: 0.8;
}

.comment_icon {
    background-image: url(../images/comment_ico.png);
}

.arrow_left_icon {
    background-image: url(../images/arrow_left.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    display: inline-block;
}

.icon_placer {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    display: inline-block;
}

.icon_placer:hover {
    -ms-transform: scale(1.2);
    /* IE 9 */
    -webkit-transform: scale(1.2);
    /* Safari 3-8 */
    transform: scale(1.2);
}

.invitation_icon {
    background-image: url(../images/send_invitation.png);
}

.guest_icon {
    background-image: url(../images/guest.png);
}

.registration_icon {
    background-image: url(../images/registeration.png);
}

.reward_icon {
    background-image: url(../images/get_rewards.png);
}

.btn_outline_blue {
    border: 2px solid #0471a8;
    color: #0471a8;
    border-radius: 10px;
    padding: 0.5rem 0.8rem;
    font-size: 1.05rem;
    font-weight: 600;
    letter-spacing: 0.5px;
}

.btn_outline_blue:hover {
    color: #fff;
    background-color: #0471a8;
}

.editableTab_input {
    color: #666;
    border: none;
    outline: none;
}

.editableTab_input:focus {
    background-color: #E1F3FF;
    border: 1px solid #B1D1DF;
    outline: none;
}


.question_card:focus-within {
    border: 2px solid var(--blue);
}

.question_card:focus-within .dnd_question {
    display: block;
}

.dnd_question {
    display: none;
}

.option_input:focus-within .dnd_options {
    display: block;
}

.dnd_options {
    display: none;
}

.blink_text {
    animation: blinker 1s linear infinite;
}



.hidden_marks .rc_slider_mark_text {
    display: none;
}


@keyframes blinker {
    50% {
        opacity: 0;
    }
}