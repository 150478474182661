.dashboard_ico {
    background-image: url(../images/dashboard_sidebar_icon.png);
}



.project_ico {
    background-image: url(../images/briefcase.png);
}

.community_ico {
    background-image: url(../images/community_icon.svg);
}

.rewards_ico {
    background-image: url(../images/rewards_icon.svg);
}
.admin_rewards_ico {
    background-image: url(../images/magic_star.png);
}

.notifications_ico {
    background-image: url(../images/notifications_icon.svg);
}

.settings_ico {
    background-image: url(../images/settings.svg);
}

.help_ico {
    background-image: url(../images/HelpQuestion.svg);
}

.logout_ico {
    background-image: url(../images/SignOut.svg);
}


/* new projects tags  */

.new_projects_tags {
    display: flex;


    align-items: flex-start;
    gap: 20px;
    flex-shrink: 0;
    overflow-x: auto;
    scrollbar-width: none
}

.project_text {
    color: #000000;
    font-family: "DM Sans-Regular", Helvetica;
    font-size: 18px;
    font-weight: 400;
    left: 0;
    letter-spacing: 0;
    line-height: normal;
    position: fixed;
    top: 0;
    width: 77px;
}

.continue_project_title {
    color: #000;

    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    padding-top: 10px
}

.continue_project_title2 {
    color: #000;

    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    padding-top: 10px
}


.new_projects_tags_button:hover {
    background-color: #0069AB;
    color: #ffffff;
    border: none;
}

.new_projects_tags_button:active {
    background-color: #0069AB;
    color: #ffffff;
    border: none
}


.new_projects_tags_button {
    border-radius: 26px;
    border: 1px solid #EAEAEA;
    color: #000000;
    background-color: #FFF;
    width: auto;
    height: 52px;
    flex-shrink: 0;
    transition: background-color 0.3s, color 0.3s;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 130px;
    padding: 0px 12px;
    overflow: hidden;
    min-width: 130px;
}

.login_tags_button {
    border-radius: 26px;
    border: 1px solid #EAEAEA;
    color: #000000;
    background-color: #FFF;
    width: auto;
    height: 52px;
    flex-shrink: 0;
    transition: background-color 0.3s, color 0.3s;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100px;
    padding: 0px 12px;
    overflow: hidden;
    min-width: 90px;
}

.login_tags_button:hover {
    background-color: #0069AB;
    color: #ffffff;
    border: none;
}

.login_tags_active_button {
    /* Styles for the active state */

    border-radius: 26px;
    border: 1px #EAEAEA;
    color: #ffffff;
    background-color: #0069AB;
    width: auto;
    height: 52px;
    flex-shrink: 0;
    transition: background-color 0.3s, color 0.3s;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100px;
    padding: 0px 12px;
    overflow: hidden;
    min-width: 90px;
}

.new_projects_tags_button_active_button {
    /* Styles for the active state */

    border-radius: 26px;
    border: 1px #EAEAEA;
    color: #ffffff;
    background-color: #0069AB;
    width: auto;
    height: 52px;
    flex-shrink: 0;
    transition: background-color 0.3s, color 0.3s;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 130px;
    padding: 0px 12px;
    overflow: hidden;
    min-width: 130px;
}

.card_border {
    border: 2px solid #ddd;
    border-radius: 10px;
}

.card_border_borderless {
    /* border: 2px solid #ddd; */
    border-radius: 10px;
}

.card_border_borderfull {
    border: 2px solid #e27070;
    border-radius: 10px;
    height: auto;

}

.refer_block_text {
    color: #000;
    /* font-family: Plus Jakarta Sans; */
    font-size: 19px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.48px;

}

.welcome_user_text {
    color: #000;
    font-family: Poppins;
    font-size: 21px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 0.48px;
}

.welcome_user_text_bold {
    color: #000;
    font-family: Poppins;
    font-size: 21px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.48px;
}

.view_profile_page {
    border-radius: 30px;
    background: #0069AB;
    border-style: none;
    height: 38px;
    width: 148px;
    color: white
}

.view_profile_page_red {
    border-radius: 30px;
    background: #0069AB;
    border-style: none;
    height: 28px;
    width: 150px;
    color: white
}

.points {
    color: #000;
    /* font-family: Plus Jakarta Sans; */
    font-size: 45px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.connect_rewards {
    color: #ADA7A7;

    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.badge {
    display: flex;
    width: 45px;

    justify-content: center;
    align-items: center;
    /* flex-shrink: 0; */
    margin-bottom: 20px;
    margin-right: 20px
}

.reward_badge {
    
    width: 20px;
    height:20px;
    


   
}

.apply_now_button {
    border-radius: 9px;
    background: #BCE5FF;
    width: inherit;
    height: 40px;
    flex-shrink: 0;
    border-style: none;
}

.apply_now_text {
    color: #0069AB;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.project_tag_title {
    color: #000;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration-line: underline;
    cursor: pointer
}

.group_forum_title {
    color: #000;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration-line: underline;
    cursor: pointer
}


.project_tag_banner {
    width: inherit;

    flex-shrink: 0;
    border-radius: 8px;
}

.project_tag_banner2 {
    /* width: 90px;
height: 90px; */
    flex-shrink: 0;
    border-radius: 8px;
    width: inherit
        /* margin-left: 18px;  */
}

.project_tag_banner3 {
    width: inherit;
    flex-shrink: 0;
    border-radius: 8px;

}

.continue_project_admin_avatar {
    width: inherit;
    height: 38px;
    flex-shrink: 0;
    border-radius: 8px;

    /* margin-top: 15px; */
}

.continue_project_admin_avatar2 {
    width: inherit;
    height: 38px;
    flex-shrink: 0;
    border-radius: 8px;
}

.group_forum_admin_avatar {
    width: inherit;
    height: 70px;
    flex-shrink: 0;
    border-radius: 8px;
}

.group_forum_respondent_avatar {
    width: inherit;
    height: 50px;
    flex-shrink: 0;
    border-radius: 30px;
    background-color: #84d0f7;
}

.continue_project_percentage {
    color: #366568;
    padding-top: 8px;
    font-size: 17px;
    font-weight: 600;
    padding-left: 50px
        /* margin-top: 15px; */
}

.activity_block {

    display: flex;
    /* align-content:center; */
    justify-content: center;
    align-items: center;
}

.last_two_nav_text {

    display: block;
    line-height: 2.5rem;
    height: 2.5rem;



    color: gray;

    font-size: 0.93rem;
    letter-spacing: 0.3px;
    font-weight: 500;
}



.comment_form {
    display: flex;
}

.comment_input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px 0 0 4px;
}

.submit_button {
    background-color: white;
    color: #166fd4;
    border: 1px solid #ccc;
    border-radius: 0 4px 4px 0;
    cursor: pointer;
    padding: 10px;
}

.submit_button span {
    font-size: 20px;
}

.spectrum_option {
    color: #000;
    text-align: center;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.btn-hover-shine:hover:after {
    width: 100% !important;
}

@media (max-width: 575px) {
    .login_img {
        max-height: 415px !important;

        height: 100%;

    }

    .login_container {
        box-shadow: 0 0 0px 0px #00000000 !important;
    }
}

.login_section {
    max-width: 450px;
}

.login_container {
    background-color: #fff;
    box-shadow: 0 0 20px 0px #e1e1e1;
    border-radius: 10px;
    width: 100%;
    max-width: 1200px;
    display: flex;
    align-content: center;
    align-items: center;
}

.login_logo {
    margin: 2rem 0rem;
}

.login_img {
    height: 95vh;
    background-position: center;
    background-size: cover;
    border-radius: 8px;
}

.login_img_thankyou {
    height: 65vh;
    background-position: center;
    background-size: cover;
    border-radius: 8px;
}

.login_img_signup {
    height: 170vh;
  background-position: center;
  background-size: contain;
  border-radius: 8px;
  background-repeat: no-repeat;
  position: sticky;
  top: 0px;
}

.h_auto_100 {
    height: 100vh;
    max-height: 100vh;
}



.pulse_button {
    background-color: #0069AB;
    border: none;
    border-radius: 50px;
    color: white;
    padding: 5px 10px;
    font-size: 12px;
font-weight: 5rem;
    cursor: pointer;
    outline: none;
    position: relative;
    z-index: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
   
    max-width: 200px;
    transition: background-color 0.3s;
    animation: pulse 0.5s infinite;
}

@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 rgba(52, 152, 219, 0.7);
    }

    70% {
        box-shadow: 0 0 0 10px rgba(52, 152, 219, 0);
    }

    100% {
        box-shadow: 0 0 0 0 rgba(52, 152, 219, 0);
    }
}



.gift_card_container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 16px;
    padding: 0px;
  }
  
  .gift_card {
    background: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    text-align: center;
    padding: 0px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.19);
  }
  
  .gift_card_image {
    width: 100%;
    height: auto;
    object-fit: contain;
    margin: 0;
    /* filter:brightness(0.9) */
  }
  
  .gift_card_title {
    font-size: 16px;
    margin: 8px 0;
    color: #000;
  font-style: normal;
  font-weight: 500;

  }
  
  .gift_card_price,
  .gift_card_points {
    font-size: 14px;
    margin: 4px 0;
    /* color: rgb(58, 57, 57); */
  font-weight: 500;
  }
  
  .gift_card_button {
    background-color: #0069AB;
    color: #fff;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
  }
  
  /* .gift_card_button:hover {
    background-color: #0056b3;
  } */
  

