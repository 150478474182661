// Header Base

.app-header {
  box-shadow: 0 18px 20px 0px #0000000f;
  height: $app-header-height;
  display: flex;
  align-items: center;
  align-content: center;
  position: relative;
  z-index: 10;
  transition: all .2s;

  &.header-shadow {
    box-shadow: $box-shadow-default;
  }

  .app-header__content {
    display: flex;
    align-items: center;
    align-content: center;
    flex: 1;
   
    // padding: 0 $layout-spacer-x;
     padding: 0 3.5rem;
    height: auto;

    .app-header-left {
      display: flex;
      // align-items: center;
      flex-direction: column;
        }

        .app-header-middle {
          display: flex;
           align-items: center;
         
        
            }

    .app-header-right {
      
      display: flex;
      margin-left: auto;
    }
  }

  // @media (max-width: 768px) {
  //   .app-header__content {
  //     flex-direction: column; /* Change to a column layout on small screens */
  //    // align-items: flex-start; /* Align items to the start of the column */
  //   }

  
  
  //   .app-header-middle {
  //     margin-top: 2px; /* Add margin to create space between app-header-left and app-header-middle */
     
  //   }
  // }


  .header-user-info {
    & > .widget-heading,
    & > .widget-subheading {
      white-space: nowrap;
    }

    & > .widget-subheading {
      font-size: $font-size-xs;
    }
  }
}

.app-header__logo {
  padding: 0 $layout-spacer-x;
  height: $app-header-height;
  width: $app-sidebar-width;
  display: flex;
  align-items: center;
  transition: width .2s;

  .logo-src {
    height: $logo-height;
    width: $logo-width;
    background-image: url('~utils/images/logo-inverse.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
}

.app-header__menu,
.app-header__mobile-menu {
  display: none;
  padding: 0 $layout-spacer-x;
  height: $app-header-height;
  align-items: center;
}

// Header Modifiers

@import "modifiers/fixed-header";
@import "modifiers/header-dots";
@import "modifiers/header-megamenu";
@import "modifiers/header-buttons";
//@import "modifiers/header-horizontal";

// Header Themes

@import "themes/header-light";
@import "themes/header-dark";
//@import "themes/header-inverted";