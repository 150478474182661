:root {
    --blue: #006FA7;
    --gray: #E8E6E5;
    --white: #ffffff;
}
  
.bg_gray {
    background-color: var(--gray);
    border-color: var(--gray);
}
.bg_gray:hover {
    background-color: var(--gray);
    border-color: var(--gray);
}
.card_chart {
    border-radius: 5px;
    border: 1px solid gainsboro;
    height: 100% ;
}

.card_title {
    opacity: 100 !important;
    font-weight: 200px !important;
}

.card_text {
    font-size: 10px;
    opacity: 100 !important;

}

.charticon {
    border-radius: 4px !important;
    margin: 10px 0px 10px 0px !important;
    display: flex !important;
}
.gradient_grbl {
    background-image: linear-gradient(120deg, #9FE6A4 0%, #4ED16B 32%, #2858DE 103%);

}
.text_blue {
    color: var(--blue);
}
.text_blue:hover {
    color: var(--blue);
}
.bg_blue {
    background-color: var(--blue) !important;
    border-color: var(--blue) !important;
}
.bg_blue:hover, .bg_blue:focus {
    background-color: var(--blue) !important;
    border-color: var(--blue) !important;
}
.text_green {
    color: #9FE6A4;
}

.card_border {
    border: 2px solid #f2f1f1;
    border-radius: 10px;
}

.card_border_header {
    border-bottom: 2px solid #f2f1f1;
    padding: 0.5rem 1rem;
    color: #999;
    font-size: 0.95rem;
    font-weight: 500;
}

.card_border_body {
    padding: 1.5rem 0.8rem;
}

.pl_30 {
    margin-left: -30px !important;
}

.header_btn_m {
    margin-right: 20rem !important;
}

.search_input {
    height: 1.5rem !important;
    border-top-left-radius: 0;
}

.heading_light {
    font-size: 1.15rem;
    font-weight: 300;
    opacity: 6;
    text-transform: capitalize;
    padding: 2rem 0 0 0;   
}

.text_bold {
    font-size: 0.9rem;
    font-weight: 500;
    padding: 1rem 0 0 0;
    text-transform: capitalize;
}
.text {
    font-size: 0.8rem;
    font-weight: 600;
    padding: 10px 25px;
    text-transform: capitalize;
    margin: 2px;
}
table > tbody > tr > td, th {
    padding-left: 1rem !important;
}
.tbl_head {
    color: white;
    background-color: var(--blue);
    width: 10rem;
    font-weight: 300;
    font-size: 1rem;
}

.buttonIn {
    width: 300px;
    position: relative;
}
  
.input {
    margin: 0px;
    padding: 0px;
    width: 100%;
    outline: none;
    height: 30px;
    border-radius: 5px;
}
  
.button {
    position: absolute;
    top: 0;
    border-radius: 5px;
    right: 0px;
    z-index: 2;
    border: none;
    top: 2px;
    height: 30px;
    cursor: pointer;
    color: white;
    background-color: #1e90ff;
    transform: translateX(2px);
}
  
.custom_list li::marker {
    font-weight: bold;
    font-size: 1rem;
  }

.sidebar_menu_icon {
    background-size: 25px;
    background-repeat: no-repeat;
    background-position: center;
    display: inline-block;
}

.arrow_left_icon {
    background-image: url(../images/arrow_left.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    display: inline-block;
}

.research_project_icon {
    background-image: url(../images/research_project_icon.png);
}

.quicksurvey_icon {
    background-image: url(../images/quick_survey_icon.png);
}

.broadcast_message_icon {
    background-image: url(../images/inperson_forum_icon.png);
}

.setting_icon {
    background-image: url(../images/setting_icon.png);
}

.chat_icon {
    background-image: url(../images/comment_ico.png);
}

.user_management_icon {
    background-image: url(../images/user_management_icon.png);
}

.templates_icon {
    background-image: url(../images/template_icon.png);
}
.interview_icon {
    background-image: url(../images/interview_icon.png);
}

.is_invalid {
    border: 1px solid #d92550;
}

.login_background {
    background-image: url('../utils/images/sidebar/city5.jpg');
}

.eye_icon {
    background: url(../images/eye_icon.png) no-repeat scroll 7px 7px;
    padding-left:30px;
}

.close_eye_icon {
    background: url(../images/close_eye_icon.png) no-repeat scroll 7px 7px;
    padding-left:30px;
}

.password_ico_position {
    cursor: pointer;
    position: absolute;
    bottom: 24px;
    height: 16px;
    right: 0;
    padding: 4px 8px;
    border-radius: 4px;
    font-weight: 700;
    font-size: 1.3rem;
}
.password {
  display: block;
  position: relative;
  /* width: 50%; */
}
.cursor {
    cursor: pointer !important;
}
.banner_image {
    border-radius: 10px ; 
    max-height: 400px ; 
    width: -webkit-fill-available ;
}

.sent_blue_btn {
    background-color: var(--blue);
    border-radius: 8px;
    border: none;
}

.profile_uploader {
    width: 150px;
    height: 150px;
    border-radius: 0.25rem;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    /* box-shadow: 0 0 11px 1px #adadad; */
    border: 1px solid #ccc;
}

.profile_upload_setting_icon {
    background-image: url(../images/setting_icon.png);
    background-size: 26px;
    background-repeat: no-repeat;
    background-position: center;
    display: block;
    background-color: #fff;
    border-radius: 100%;
    float: right;
    margin-top: -1rem;
    margin-right: -1rem;
    padding: 16px;
    cursor: pointer;
}
.wave {
    animation-name: wave-animation;  /* Refers to the name of your @keyframes element below */
    animation-duration: 2.5s;        /* Change to speed up or slow down */
    animation-iteration-count: infinite;  /* Never stop waving :) */
    transform-origin: 70% 70%;       /* Pivot around the bottom-left palm */
    display: inline-block;
  }
  
  @keyframes wave-animation {
      0% { transform: rotate( 0.0deg) }
     10% { transform: rotate(14.0deg) }  /* The following five values can be played with to make the waving more or less extreme */
     20% { transform: rotate(-8.0deg) }
     30% { transform: rotate(14.0deg) }
     40% { transform: rotate(-4.0deg) }
     50% { transform: rotate(10.0deg) }
     60% { transform: rotate( 0.0deg) }  /* Reset for the last half to pause */
    100% { transform: rotate( 0.0deg) }
  }

.profile_upload_setting_icon:hover {
    box-shadow: 0 0 11px 1px #ccc;
    opacity: 0.9;
}


@media screen and (max-width: 780px) {
    .m4 {
        margin-top: 4%;
    }
    .search_input {
        height: 1.5rem !important;
        border-top-left-radius: 0;
    }
}

@media screen and (max-width:600px) {
    .pl_30 {
        margin-left: -15px !important;
    }
}


  
  
  
  
 