






.body-tabs.body-tabs-layout.body-tabs-big .RRT__tab.RRT__tab--selected:hover {
    color: #fff;
}

.vertical-nav-menu .metismenu-container .metismenu-link {
    line-height: 3.5rem;
    height: 3.5rem;
}

.vertical-nav-menu .metismenu-container i.metismenu-state-icon {
    right: 25px;
}

._survey-steps_ .forms-wizard li.form-wizard-step-doing::before, ._survey-steps_ .forms-wizard li.form-wizard-step-doing em, ._profile_steps_ .forms-wizard li.form-wizard-step-doing::before, ._profile_steps_ .forms-wizard li.form-wizard-step-doing em {
    background-color: #0069AB;
}

._survey-steps_ .custom-control-input:checked~.custom-control-label:before {
    color: #fff;
    border-color: #666;
    background-color: #ffffff;
}

._survey-steps_ .custom-radio .custom-control-input:checked~.custom-control-label:after {
    filter: brightness(0.3);
    background-size: 25px;
}

._survey-steps_ .custom-control-label:after, .custom-control-label:before {
    width: 25px;
    height: 25px;
}

._survey-steps_ .custom-checkbox .custom-control-input:checked~.custom-control-label::after {
    filter: brightness(0.3);
    background-size: 20px;
}

._survey-steps_ textarea, ._profile_steps_ textarea {
    resize: unset;
    height: 120px;
    padding: 1rem;
}

._survey-steps_ .questionImg_close, ._survey-steps_ .questionVideo_close {
    font-size: 28px;
    color: #333333;
    background-color: #fff;
    position: absolute;
    border: 1px solid #999;
    border-radius: 100%;
    margin-left: 177px;
    margin-top: -20px;
    padding: 0px 12px;
    cursor: pointer;
}

._survey-steps_ .questionImg_close:hover, ._survey-steps_ .questionVideo_close:hover {
    box-shadow: 0px 0px 7px 0px #aaa;
    transform: scale(1.05);
}

._survey-steps_ textarea::placeholder, ._survey-steps_ input::placeholder {
    color: #bbb;
}

._survey-steps_ .table thead th {
    text-align: center;
    text-transform: capitalize;
    background-color: '';
    color: #000;
    font-weight: 500;
    letter-spacing: 0.5px;
}

._survey-steps_ .table-bordered th, .table-bordered td {
     border: 2px solid #ddd; 
    text-align: center;
    
    
}

._survey-steps_ .table-striped tbody tr:nth-of-type(odd) {
    background-color: #e8e6e5;
}

._survey-steps_ .table {
    color: #666;
    font-weight: 500;
    font-size: 1rem;
}

._survey-steps_ .table-responsive {
    /* border-top-left-radius: 20px;
    border-top-right-radius: 20px; */
}

._survey-steps_ table .custom-radio .custom-control-input:checked~.custom-control-label::after {
    /* background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='white' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e"); */
    /* background-image: url('https://icon2.cleanpng.com/20180203/hxe/kisspng-scalable-vector-graphics-check-mark-clip-art-green-tick-transparent-png-5a756eedaab886.2064827915176455496993.jpg'); */
    background-image: url('../images/grid-tick.png');
    background-size: 16px;
    filter: brightness(1);
}

._survey-steps_ table .custom-control-input:checked~.custom-control-label:before {
    /* background-color: #00c95a;
    border-color: #00c95a; */
    border-color:#fff
}

._survey-steps_ .spectrum-option {
    width: 100%;
    background-color: #ccc;
    border-radius: 8px;
    padding: 1rem;
    font-size: 2rem;
    font-weight: 600;
    text-align: center;
    color: #fff;
}

._survey-steps_ .spectrum-option:hover {
    transform: scale(1.08);
    background-color: #006fa7;
    transition: all 0.5s;
    cursor: pointer;
}

._survey-steps_ .spectrum-option.active {
    background-color: #006fa7;
}

._survey-steps_ .spectrum-strip {
    width: 100%;
    height: 10px;
    background-image: linear-gradient(to right, #ff2828, #ffaa0e, #f6f66c, #8be28b);
}

._survey-steps_ .rc-slider-rail {
    height: 6px;
    background-image: linear-gradient(to right, #ff2828, #ffaa0e, #f6f66c, #8be28b);
}

._survey-steps_ .rc-slider-track {
    background-color: transparent;
}

._survey-steps_ .rc-slider-dot {
    width: 25px;
    height: 25px;
    bottom: -12.5px;
    margin-left: -21px;
    border: 2px solid #999;
    z-index: 1;
}

._survey-steps_ .rc-slider-dot-active {
     border-color: #006fa7; 
    transform: scale(1.25);
}

._survey-steps_ .rc-slider-mark {
     top: 34px; 
      left: -8px;  
    font-size: 15px;
}

._survey-steps_ .rc-slider-mark-text-active {
    color: #006fa7;
    
    font-weight: 500;
}

._referral_ .rbt-input-multi.form-control {
    height: auto;
    border-radius: 10px;
    border: 0;
    padding: 0.5rem;
}

._referral_ .rbt-input-multi .rbt-input-wrapper {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -4px;
    margin-top: -1px;
    overflow: hidden;
}

._referral_ .dropdown-item.active, .dropdown-item:active {
    background-color: #006fa7;
}

._referral_ .input-group-append {
    border: 0;
}

._referral_ .rbt-menu.dropdown-menu.show {
    animation: none;
}

._referral_ .rbt-token {
    font-size: 1.1rem;
    font-weight: 300;
    padding: 0.8rem 2.2rem 0.8rem 1rem;
    color: #fff;
    background-color: #0070a8;
    border-radius: 10px;
    letter-spacing: 0.5px;
    margin-right: 6px;
    max-width: calc(100vw - 465px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

._referral_ .rbt-token .rbt-token-remove-button {
    color: #fff;
    top: 2px;
    right: 3px;
    font-size: 2rem;
    font-weight: 200;
}

._referral_ .refer-email-input input, .__settings__ .city-settings-input input {
    border: none;
}

._referral_ .refer-email-input input:focus, .__settings__ .city-settings-input input:focus {
    /* outline: none; */
    border-color: rgba(126, 239, 104, 0.8);
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(126, 239, 104, 0.6);
    outline: 0 none;
}

/* ._referral_ .city-settings-input input::-webkit-input-placeholder {
    font-size: 5px !important;
} */

/* input[type="text"]:focus, textarea {
    outline: none;
    box-shadow:none !important;
    border:1px solid #ccc !important;
    } */

    .__settings__ .city-settings-input  .form-control:focus {
        outline: none;
        box-shadow:none !important;
        border:none !important
      }

.link:hover {
    opacity: 0.8;
}

/* Activity card */

._activity_card_ {
    max-width: 550px;
    width: 100%;
    height: 450px;
    position: relative;
    color: #fff;
    top: 0;
    right: 0;
    left: 0;
    margin: 0 auto;
    overflow: hidden;
    box-shadow: 0px 10px 20px -9px rgba(0, 0, 0, 0.5);
    text-align: center;
    transition: all 0.4s;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    border-radius: 15px;
}

._activity_card_ a {
    color: #fff;
    text-decoration: none;
    transition: all 0.2s
}

._activity_card_ .color-overlay {
    background: rgba(64, 84, 94, 0.5);
    width: 550px;
    height: 450px;
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    transition: background 0.3s cubic-bezier(0.33, 0.66, 0.66, 1);
}

._activity_card_ .gradient-overlay {
    background-image: linear-gradient(transparent 0%, rgba(0, 0, 0, 0.6) 21%);
    width: 550px;
    height: 500px;
    position: absolute;
    top: 350px;
    left: 0;
    z-index: 15;
}

._activity_card_:hover {
    box-shadow: 0px 18px 20px -9px rgba(0, 10, 30, 0.75);
}

._activity_card_:hover .card-info {
    opacity: 1;
    bottom: 100px;
}

._activity_card_:hover .color-overlay {
    background: rgba(64, 64, 70, 0.8);
}

._activity_card_:hover .title-content {
    margin-top: 70px
}

._activity_card_ .title-content {
    text-align: center;
    margin: 170px 0 0 0;
    position: absolute;
    z-index: 20;
    width: 100%;
    top: 0;
    left: 0;
    transition: all 0.6s
}

._activity_card_:hover h3:after {
    animation: changeLetter 0.3s 1 linear;
    width: 80%
}

._activity_card_ h3, h1 {
    font-size: 1.9em;
    font-weight: 400;
    letter-spacing: 1px;
    margin-bottom: 0;
    display: inline-block;
}

._activity_card_ h3 a {
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    transition: all 0.2s;
    color: #fff;
}

._activity_card_ h3 a:hover {
    text-shadow: 0px 8px 20px rgba(0, 0, 0, 0.95);
}

._activity_card_ h3:after {
    content: " ";
    display: block;
    width: 10%;
    height: 2px;
    margin: 20px auto;
    border: 0;
    background: #BDA26B;
    transition: all 0.2s
}

@keyframes changeLetter {
    0% {
        width: 10%;
    }

    100% {
        width: 80%;
    }
}

._activity_card_ .intro {
    width: 170px;
    margin: 0 auto;
    color: #ddd;
    font-style: italic;
    line-height: 18px;
}

._activity_card_ .intro a {
    color: #ddd
}

._activity_card_ .intro a:hover {
    text-decoration: underline
}

._activity_card_ .card-info {
    box-sizing: border-box;
    padding: 0;
    width: 100%;
    position: absolute;
    bottom: -40px;
    left: 0;
    margin: 0 auto;
    padding: 0 50px;
    font-style: 16px;
    line-height: 24px;
    z-index: 20;
    opacity: 0;
    transition: bottom 0.64s, opacity 0.63s cubic-bezier(0.33, 0.66, 0.66, 1);
}

._activity_card_ .card-info a {
    display: block;
    width: 100px;
    margin: 15px auto;
    background: #fff;
    color: #444;
    padding: 3px 10px;
    border-radius: 2px;
    font-size: 0.8em
}

._activity_card_ .card-info a:hover {
    background: #8e7c49;
    color: #fff;
}

._activity_card_ .card-info a:hover span {
    filter: brightness(10);
    opacity: 1
}

._activity_card_ .utility-info {
    position: absolute;
    bottom: 0px;
    left: 0;
    z-index: 20;
    width: 100%;
    text-align: left
}

._activity_card_ .utility-info:after {
    content: " ";
    background: url(https://rawcdn.githack.com/Nodws/NodPen/ffad95aa5244b4b09a3c7c1508a018959bbedb7e/postItem/licons.svg) center no-repeat;
    background-size: 30px auto;
    display: block;
    opacity: 0.4;
    position: absolute;
    bottom: 25px;
    right: 15px;
    width: 30px;
    height: 15px
}

._activity_card_ .utility-info a:hover {
    text-decoration: underline
}

._activity_card_ .utility-list {
    list-style-type: none;
    margin: 0 0 10px 20px;
    padding: 0;
    width: 100%;
}

._activity_card_ .utility-list li {
    margin: 0 5px 0 0;
    padding: 3px 0 15px 0px;
    display: inline-block;
    font-size: 0.8em
}

._activity_card_ .licon {
    position: relative;
    width: 23px;
    height: 15px;
    display: inline-block;
    vertical-align: middle;
}

._activity_card_ .licon:before {
    content: "";
    background: url(https://rawcdn.githack.com/Nodws/NodPen/ffad95aa5244b4b09a3c7c1508a018959bbedb7e/postItem/licons.svg?) -2px -6px no-repeat;
    background-size: 250px;
    width: 26px;
    height: 20px;
    display: inline-block;
    vertical-align: middle;
    position: absolute;
    top: -3px;
    left: 0;
}

._activity_card_ .icon-white {
    filter: brightness(10);
}

._activity_card_ .icon-black {
    filter: brightness(0);
    opacity: 0.6
}

._activity_card_ .icon-like:before {
    background-position: -183px -6px;
}

._activity_card_ .icon-com:before {
    background-position: -63px -4px;
}

._activity_card_ .icon-dat:before {
    background-position: -94px -7px;
}

._activity_card_ .icon-tag:before {
    background-position: -33px -6px;
}

@media (max-width:750px) {
    ._activity_card_ .utility-info {
        text-align: center;
    }

    ._activity_card_ .utility-info ul {
        width: 100%;
        margin: 0;
        box-sizing: border-box
    }

    ._activity_card_ .utility-info li {
        width: 49%;
        display: inline-block;
        box-sizing: border-box;
        margin: 0
    }
}

@media (max-width: 768px) {
    ._profile_steps_ .forms-wizard {
      /* width: auto !important; */
    }
    ._profile_steps_ .forms-wizard li em {
      /* margin-top: 1.3rem !important; */
    }
    ._profile_steps_ .forms-wizard li {
      /* vertical-align: text-top; */
    }
  }

@media (max-width:500px) {
    ._activity_card_ .utility-info li:last-of-type {
        width: 100%
    }

    ._activity_card_ .card-info p {
        display: none
    }

    ._activity_card_ .card-info a {
        display: block !important
    }

    ._activity_card_:hover .title-content, .title-content {
        margin-top: 40px
    }

    ._activity_card_ {
        height: 300px
    }

    ._activity_card_ h3 {
        font-size: 1.3em
    }

    ._activity_card_ .intro {
        font-size: 0.8em
    }
}

/* Signup Page */

._signup_ .side_bg {
    width: 100%;
    min-height: 100vh;

    background-image: url('../images/login_bg.jpg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

._signup_ .grad_overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    background: linear-gradient(90deg, rgba(0, 201, 90, 0.8127626050420168) 1%, rgba(0, 196, 94, 0.8463760504201681) 11%, rgba(0, 191, 98, 0.8099614845938375) 18%, rgba(0, 184, 104, 0.865983893557423) 26%, rgba(0, 169, 117, 0.8995973389355743) 34%, rgba(0, 162, 123, 0.8631827731092436) 38%, rgba(0, 147, 135, 0.8295693277310925) 47%, rgba(0, 105, 171, 0.8323704481792717) 99%);
}


/* Default styles for large screens */
.large_screens {
    display: table-row;
}

.small_screens {
    display: none;
}

/* Media query for small screens (e.g., mobile devices) */
@media (max-width: 767px) {
    .large_screens {
        display: none;
    }

    .small_screens {
        display: table-row;
    }
}
